import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import VerifyEmail from './components/Onboarding/VerifyEmail';
import { AppRouter } from './pages';
import PanelLayout from './layout';
import { GraphQLProvider } from './providers/GraphQLProvider';
import './styles/index.css';
import { SettingsProvider, useCustomerSettings } from './contexts/CustomerSettingsContext';
import { LoadingSpinner } from './components/Utils';
import SignupForm from './components/Onboarding/Signup';
import Login from './components/Onboarding/Login';
import PaymentSuccess from './components/Onboarding/PaymentSuccess';
import { SubscriptionPlansList } from './components/Onboarding/SubscriptionPlan';
import PaymentCancelled from './components/Onboarding/PaymentCancelled';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '');

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const [hasRedirected, setHasRedirected] = useState(false);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isAuthenticated) {
    const customerSettingsMethods = useCustomerSettings();
    // brand new user with no subscription
    if (
      customerSettingsMethods?.customer?.status === 'pending' &&
      customerSettingsMethods?.customer?.currentSubscription === null
    ) {
      return <SubscriptionPlansList />;
    }

    // brand new user with subscription freshly bought
    if (customerSettingsMethods?.customer?.currentSubscription !== null && !hasRedirected) {
      console.log('Redirecting to new controller wizard');
      setHasRedirected(true);
      navigate('/new-controller-wizard');
    }
  }

  return isAuthenticated ? children : null;
};

function AppContent() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Routes>
      {/* Public route for signup */}
      <Route path="/signup" element={<SignupForm />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/login" element={<Login />} />
      <Route path="/payment-cancelled" element={<PaymentCancelled />} />
      <Route path="/success" element={<PaymentSuccess />} />

      {/* Protected routes for authenticated users */}
      <Route
        path="/*"
        element={
          <ProtectedRoute>
            <PanelLayout>
              <AppRouter />
            </PanelLayout>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

function App() {
  return (
    <Elements stripe={stripePromise}>
      <GraphQLProvider>
        <SettingsProvider>
          <AppContent />
        </SettingsProvider>
      </GraphQLProvider>
    </Elements>
  );
}

export default App;
