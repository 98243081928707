import React, { FC, useState, useEffect } from 'react';
import { Box, Button, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import BackButton from '../Common/BackButton';
import { VideoAssetFieldsEnum } from '../types';
import { UploadedMovieFileInputType } from '../../interfaces';

import { videoAssetsSchema } from './videoAssetsSchema';
import DragAndDropInput from '../Common/DragAndDropInput';
import { TextInput } from '../Inputs';
import { useCreateAssetMutation } from '../../hooks/api/createAsset/createAsset.generated';
import { LoadingSpinner } from '../Utils';
import { AssetsDocument } from '../../hooks/api/assets/assets.generated';
import { useUpdateAssetMutation } from '../../hooks/api/updateAssets/updateAssets.generated';
import { useGetAssetQuery } from '../../hooks/api/asset/asset.generated';
import ErrorSnackbar from '../Utils/ErrorSnackbar';
import { TestIds } from '../../test-utils';

interface AssetFormValues {
  [VideoAssetFieldsEnum.Name]: string;
  [VideoAssetFieldsEnum.VideoAsset]: UploadedMovieFileInputType;
}

const AddNewAssetView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const isEditMode = !!id;
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<AssetFormValues>({
    resolver: yupResolver(videoAssetsSchema()),
  });

  const handleSnackbarClose = () => {
    setErrorSnackbarOpen(false);
  };

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const maxWidthValue = isLargeScreen ? '368px' : '488px';
  const paperPadding = isLargeScreen ? '32px' : '16px';

  const [createAsset] = useCreateAssetMutation({
    refetchQueries: [{ query: AssetsDocument }],
  });

  const [updateAsset] = useUpdateAssetMutation({
    refetchQueries: [{ query: AssetsDocument }],
  });

  const { data: assetData, loading: assetLoading } = useGetAssetQuery({
    variables: { id: id || '' },
    skip: !isEditMode,
  });

  useEffect(() => {
    if (isEditMode && assetData?.asset) {
      reset({
        [VideoAssetFieldsEnum.Name]: assetData.asset.name,
        [VideoAssetFieldsEnum.VideoAsset]: assetData.asset.videoAsset,
      });
    }
  }, [isEditMode, assetData, reset]);

  const onSubmit = async (values: AssetFormValues) => {
    setIsSubmitting(true);
    try {
      if (isEditMode) {
        await updateAsset({
          variables: {
            id,
            input: {
              ...values,
            },
          },
        });
      } else {
        await createAsset({
          variables: {
            input: {
              ...values,
            },
          },
        });
      }
      await navigate(`/assets`);
    } catch (error) {
      setErrorMessage((error as Error)?.message || 'Unknown error');
      setErrorSnackbarOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isEditMode && assetLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Box data-testId={TestIds.UPLOAD_FORM.CONTAINER}>
        <BackButton label="Back to videos" path="/assets" />
        <Typography fontSize={36} fontWeight={700} pb={6} pt={4}>
          {isEditMode ? 'Edit video' : 'Add a new video to your library'}
        </Typography>
        <Paper style={{ padding: paperPadding }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item lg={6} sm={12} xs={12}>
                <Box display="flex" flexDirection="column" gap={3}>
                  <TextInput
                    {...register(VideoAssetFieldsEnum.Name)}
                    errors={errors}
                    label="Your video will be visible in library with that name"
                    onChange={(value) => setValue(VideoAssetFieldsEnum.Name, value.target.value)}
                  />
                  <DragAndDropInput
                    {...register(VideoAssetFieldsEnum.VideoAsset)}
                    errors={errors}
                    label="Add video file"
                    onFileUploadComplete={(value) => {
                      setValue(VideoAssetFieldsEnum.VideoAsset, value);
                    }}
                  />
                </Box>
                <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue} />
              </Grid>
            </Grid>
            <Box display="flex" flexDirection="row" justifyContent="end" pt={6} width="100%">
              <Button
                color="secondary"
                style={{ textTransform: 'none', padding: '13px 76px', fontWeight: '700' }}
                type="submit"
                variant="contained"
                disabled={isSubmitting}
              >
                {isEditMode ? 'Save changes' : 'Add new video'}
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
      <ErrorSnackbar
        message={errorMessage}
        onClose={handleSnackbarClose}
        open={errorSnackbarOpen}
      />
    </>
  );
};

export default AddNewAssetView;
