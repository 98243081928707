export const appConfig = {
  isPreProd: process.env.REACT_APP_IS_PRE_PROD === 'true',
  supportEmail: process.env.REACT_APP_SUPPORT_EMAIL || 'support@closer2.io',
  widgetUrl: process.env.REACT_APP_WIDGET_URL || 'http://localhost:3001/static/js/bundle.js',
  landingPageUrl: process.env.REACT_APP_LANDING_PAGE_URL || 'http://localhost:8080',
  graphqlApiUrl: process.env.REACT_APP_GRAPHQL_API_URL || 'http://localhost:4000/graphql',
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:4000',
  previewSiteUrl: process.env.REACT_APP_PREVIEW_SITE_URL || 'http://localhost:3001/preview',
  rolesNamespace: process.env.REACT_APP_ROLES_NAMESPACE || 'https://closer2.io/roles',
  video: {
    maxFileSize: process.env.MAX_FILE_SIZE
      ? parseInt(process.env.MAX_FILE_SIZE, 10)
      : 15 * 1024 * 1024, // 15 MB,
    maxVideoDuration: process.env.MAX_VIDEO_DURATION
      ? parseInt(process.env.MAX_VIDEO_DURATION, 10)
      : 30, // 30 seconds
  },
};
