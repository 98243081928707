import { SupportIcon, UsageIcon, VideoIcon } from '../assets/icons';

export const sidebarItems = [
  { path: '/new-controller-wizard', label: 'Create new controller', icon: VideoIcon },
  { path: '/assets', label: 'Video library', icon: VideoIcon },
  { path: '/controllers', label: 'Controllers', icon: VideoIcon },
  { path: '/viewer-data', label: 'Viewer data', icon: SupportIcon },
  // { path: '/analytics', label: 'Analytics (beta)', icon: SupportIcon },
  { path: '/usage', label: 'Analytics', icon: UsageIcon },
];
