import React, { FC, useState } from 'react';
import { Box, Button, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { VideoAssetFieldsEnum } from '../types';
import { UploadedMovieFileInputType } from '../../interfaces';

import DragAndDropInput from '../Common/DragAndDropInput';
import { TextInput } from '../Inputs';
import { useCreateAssetMutation } from '../../hooks/api/createAsset/createAsset.generated';
import ErrorSnackbar from '../Utils/ErrorSnackbar';
import { videoAssetsSchema } from '../Assets/videoAssetsSchema';
import FileRequirements from '../Common/FileRequirements';
import { TestIds } from '../../test-utils';

interface AssetFormValues {
  [VideoAssetFieldsEnum.Name]: string;
  [VideoAssetFieldsEnum.VideoAsset]: UploadedMovieFileInputType;
}

interface VideoUploadFormProps {
  onUploadComplete: (assetData: any) => void;
}

const VideoUploadForm: FC<VideoUploadFormProps> = ({ onUploadComplete }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AssetFormValues>({
    resolver: yupResolver(videoAssetsSchema()),
  });

  const handleSnackbarClose = () => {
    setErrorSnackbarOpen(false);
  };

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const maxWidthValue = isLargeScreen ? '368px' : '488px';
  const paperPadding = isLargeScreen ? '32px' : '16px';

  const [createAsset] = useCreateAssetMutation();

  const onSubmit = async (values: AssetFormValues) => {
    setIsSubmitting(true);
    try {
      const result = await createAsset({
        variables: {
          input: {
            ...values,
          },
        },
      });
      if (result.data?.createAsset) {
        onUploadComplete(result.data.createAsset);
      } else {
        throw new Error('Failed to create asset');
      }
    } catch (error) {
      setErrorMessage((error as Error)?.message || 'Unknown error');
      setErrorSnackbarOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Box sx={{ minHeight: '75vh' }}>
        <Typography fontSize={24} fontWeight={700} pb={4}>
          Upload a new video
        </Typography>
        <Paper style={{ padding: paperPadding }}>
          <form data-testid={TestIds.UPLOAD_FORM.CONTAINER} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column" gap={3}>
                  <TextInput
                    {...register(VideoAssetFieldsEnum.Name)}
                    errors={errors}
                    label="Video name"
                    onChange={(value) => setValue(VideoAssetFieldsEnum.Name, value.target.value)}
                  />
                  <DragAndDropInput
                    {...register(VideoAssetFieldsEnum.VideoAsset)}
                    errors={errors}
                    label="Add video file"
                    onFileUploadComplete={(value) => {
                      setValue(VideoAssetFieldsEnum.VideoAsset, value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column" gap={4} maxWidth="100%">
                  <FileRequirements />
                </Box>
              </Grid>
            </Grid>

            <Box display="flex" flexDirection="row" justifyContent="end" pt={4} width="100%">
              <Button
                color="primary"
                style={{ textTransform: 'none', padding: '13px 76px', fontWeight: '700' }}
                type="submit"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Uploading...' : 'Upload video'}
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
      <ErrorSnackbar
        message={errorMessage}
        onClose={handleSnackbarClose}
        open={errorSnackbarOpen}
      />
    </>
  );
};

export default VideoUploadForm;
