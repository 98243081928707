import React, { useState } from 'react';
import { format } from 'date-fns';
import {
  Container,
  Typography,
  Paper,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Chip,
  Avatar,
  styled,
} from '@mui/material';
import { LoadingSpinner } from '../Utils';
import { useCustomerSettings } from '../../contexts/CustomerSettingsContext';
import { BusinessIcon, EmailIcon } from '../../assets/icons';
import ErrorSnackbar from '../Utils/ErrorSnackbar';
import { useCreateBillingPortalSessionMutation } from '../../hooks/api/checkoutBillingPortal/checkoutBillingPortal.generated';
import LoadingButton from '../Utils/LoadingButtons';

// // Custom styled components
// const StyledSubtitle = styled(Typography)(({ theme }) => ({
//   color: theme.palette.text.secondary,
//   fontWeight: 600,
// }));

const IconWrapper = styled('span')({
  display: 'inline-flex',
  verticalAlign: 'text-bottom',
  marginRight: '8px',
});

const Profile = () => {
  const { customer, isLoading, error: queryError } = useCustomerSettings();
  const [createBillingPortalSessionMutation] = useCreateBillingPortalSessionMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (queryError) {
    return <Typography color="error">Error: {queryError.message}</Typography>;
  }

  if (!customer) {
    return <Typography>No customer data available</Typography>;
  }

  const { currentSubscription } = customer;

  const handleUpdatePaymentMethod = async () => {
    try {
      const { data } = await createBillingPortalSessionMutation();
      if (data?.createBillingPortalSessionUrl) {
        window.location.href = data.createBillingPortalSessionUrl.billingPortalUrl;
      }
    } catch (error) {
      setSnackbarMessage('Failed to create billing session');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        My Account
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
              <Avatar sx={{ width: 100, height: 100, mb: 2 }}>
                {customer.email[0].toUpperCase()}
              </Avatar>
              <Typography variant="h6" align="center" sx={{ wordBreak: 'break-word' }}>
                {customer.email}
              </Typography>
              <Chip
                label={customer.status}
                color={customer.status === 'active' ? 'success' : 'default'}
                sx={{ mt: 1 }}
              />
              {customer.currentSubscription?.cancellation?.cancelAt && (
                <Chip
                  label={`Cancels at ${format(
                    new Date(customer.currentSubscription.cancellation.cancelAt),
                    'PPP',
                  )}`}
                  color="warning"
                  sx={{ mt: 1 }}
                />
              )}
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box>
              <Typography variant="h6" gutterBottom>
                <IconWrapper>
                  <EmailIcon />
                </IconWrapper>
                Email
              </Typography>
              <Typography variant="body2" paragraph sx={{ wordBreak: 'break-word' }}>
                {customer.email}
              </Typography>
              {customer.companyName && (
                <>
                  <Typography variant="h6" gutterBottom>
                    <IconWrapper>
                      <BusinessIcon />
                    </IconWrapper>
                    Company
                  </Typography>
                  <Typography variant="body2" paragraph>
                    {customer.companyName}
                  </Typography>
                </>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h5" gutterBottom>
              Current Subscription
            </Typography>
            {currentSubscription ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    Plan
                  </Typography>
                  <Typography variant="body1">{currentSubscription.plan.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    Status
                  </Typography>
                  <Typography variant="body1">{currentSubscription.status}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    Start Date
                  </Typography>
                  <Typography variant="body1">
                    {format(new Date(currentSubscription.startDate), 'PPP')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    End Date
                  </Typography>
                  <Typography variant="body1">
                    {format(new Date(currentSubscription.endDate), 'PPP')}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography>No active subscription</Typography>
            )}
          </Paper>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Subscription History
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Plan</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customer?.subscriptionHistory?.map((subscription, index) => (
                    <TableRow key={index}>
                      <TableCell>{subscription.plan.name}</TableCell>
                      <TableCell>{subscription.status}</TableCell>
                      <TableCell>{format(new Date(subscription.startDate), 'PPP')}</TableCell>
                      <TableCell>{format(new Date(subscription.endDate), 'PPP')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Payment Method
          </Typography>
          <Typography variant="body2" paragraph>
            Update your payment information to ensure uninterrupted service.
          </Typography>
          <LoadingButton onClick={handleUpdatePaymentMethod} variant="contained">
            Update Payment Method
          </LoadingButton>
        </Paper>
      </Box>
      <ErrorSnackbar open={snackbarOpen} message={snackbarMessage} onClose={handleCloseSnackbar} />
    </Container>
  );
};

export default Profile;
