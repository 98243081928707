import React from 'react';
import { Box, Paper, Typography, Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useCustomerSettings } from '../../contexts/CustomerSettingsContext';
import TrustedDomainsManager from '../CreateNewControllerWizard/TrustedDomainsManager';

interface SettingsFormValues {
  trustedDomains: string[];
}

const Settings = () => {
  const { customer } = useCustomerSettings();

  const methods = useForm<SettingsFormValues>({
    defaultValues: {
      trustedDomains: customer?.customerSettings?.trustedDomains || [],
    },
  });

  const { control, setValue, watch } = methods;

  if (!customer) {
    return <Alert severity="error">Unable to load customer settings</Alert>;
  }

  return (
    <Box>
      <Typography
        variant="h1"
        sx={{
          fontSize: 36,
          fontWeight: 700,
          pb: '24px',
          pt: '44px',
        }}
      >
        Settings
      </Typography>

      <Paper
        elevation={3}
        sx={{
          p: 4,
          minHeight: '70vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Domain Management Section */}
        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Domain Management
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Configure which domains can host your widget
          </Typography>
          <TrustedDomainsManager control={control} setValue={setValue} watch={watch} />
        </Box>
      </Paper>
    </Box>
  );
};

export default Settings;
