import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Paper,
  Select,
  MenuItem,
  Box,
  TextField,
  SelectChangeEvent,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { BubbleAddOnType, ControllerType, PositioningType } from '../../interfaces';
import WidgetPreview from './WidgetPreview';
import ColorPickerV2 from '../Common/ColorPickerV2';
import ColorPicker from '../Common/ColorPicker';

const defaultGradientColors: [string, string] = ['#00df81', '#653ffe'];
const defaultBubbleText = 'get a discount!';
const defaultBubbleBackgroundColor = '#653ffe';
const defaultBubbleTextColor = '#ffffff';

type WidgetSize = 'S' | 'M' | 'L';
type WidgetShape = 'square' | 'rectangle' | 'circle';
type WidgetPosition = 'bottomLeft' | 'bottomRight';

export type BorderType = {
  enabled: boolean;
  color?: string | null;
  gradientColors?: string[] | null;
};

export type StyleMinifedWidgetConfig = {
  size: WidgetSize;
  shape: WidgetShape;
  border: BorderType;
  positioning: PositioningType;
  bubble: BubbleAddOnType;
};

interface StyleMinifiedStepProps {
  controllerData: ControllerType | null;
  handleConfigurationChange: (config: Partial<StyleMinifedWidgetConfig>) => Promise<void>;
  previewReload: number;
  clientId: string;
}

const StyleMinifiedStep: React.FC<StyleMinifiedStepProps> = ({
  controllerData,
  handleConfigurationChange,
  previewReload,
  clientId,
}) => {
  // Helper function to ensure we have valid gradient colors when needed
  const getInitialGradientColors = (
    enabled: boolean,
    configColors?: string[] | null,
  ): [string, string] | null => {
    if (!enabled) return null;

    if (Array.isArray(configColors) && configColors.length >= 2) {
      return [configColors[0], configColors[1]];
    }
    return defaultGradientColors;
  };

  const getInitialBubbleConfig = (controllerBubble?: BubbleAddOnType | null): BubbleAddOnType => {
    if (controllerBubble?.enabled) {
      return {
        enabled: true,
        text: controllerBubble.text || defaultBubbleText,
        design: {
          backgroundColor: controllerBubble.design?.backgroundColor || defaultBubbleBackgroundColor,
          textColor: controllerBubble.design?.textColor || defaultBubbleTextColor,
        },
      };
    }
    return {
      enabled: false,
      text: defaultBubbleText,
      design: {
        backgroundColor: defaultBubbleBackgroundColor,
        textColor: defaultBubbleTextColor,
      },
    };
  };

  const ensureGradientColorsTuple = (colors: string[] | null | undefined): [string, string] => {
    const defaultColors: [string, string] = ['#00df81', '#653ffe'];

    if (!colors || !Array.isArray(colors) || colors.length < 2) {
      return defaultColors;
    }

    return [colors[0], colors[1]] as [string, string];
  };

  const [widgetConfig, setWidgetConfig] = useState<StyleMinifedWidgetConfig>(() => {
    const borderEnabled = controllerData?.layout?.widget?.design.minified.border?.enabled || false;

    return {
      size: (controllerData?.layout?.widget?.design.minified.widgetSize as WidgetSize) || 'M',
      shape: (controllerData?.layout?.widget?.design.minified.shape as WidgetShape) || 'circle',
      border: {
        enabled: borderEnabled,
        gradientColors: getInitialGradientColors(
          borderEnabled,
          controllerData?.layout?.widget?.design.minified.border?.gradientColors,
        ),
      },
      positioning: controllerData?.layout?.widget?.design.minified.positioning || {
        position: 'bottomLeft',
      },
      bubble: getInitialBubbleConfig(
        controllerData?.layout?.widget?.design.minified.addOns?.bubble,
      ),
    };
  });

  useEffect(() => {
    if (controllerData?.layout?.widget?.design.minified) {
      setWidgetConfig({
        size: (controllerData.layout.widget.design.minified.widgetSize as WidgetSize) || 'M',
        shape: (controllerData.layout.widget.design.minified.shape as WidgetShape) || 'circle',
        border: {
          enabled: controllerData.layout.widget.design.minified.border?.enabled || false,
          gradientColors: controllerData.layout.widget.design.minified.border?.gradientColors || [
            '#00df81',
            '#653ffe',
          ],
        },
        positioning: controllerData.layout.widget.design.minified.positioning || {
          position: 'bottomLeft',
        },
        bubble: getInitialBubbleConfig(controllerData.layout.widget.design.minified.addOns?.bubble),
      });
    }
  }, [controllerData]);

  const debouncedHandleConfigurationChange = useCallback(
    debounce((config: Partial<StyleMinifedWidgetConfig>) => {
      handleConfigurationChange(config);
    }, 300),
    [handleConfigurationChange],
  );

  const updateConfig = (update: Partial<StyleMinifedWidgetConfig>) => {
    setWidgetConfig((prev) => {
      // Helper function to ensure we have an array
      const ensureArray = (value: any): string[] | null => {
        if (!value) return null;
        if (Array.isArray(value)) return [...value];
        if (typeof value === 'object') return Object.values(value);
        return null;
      };

      // Always handle border separately to maintain structure
      const newConfig = {
        ...prev,
        ...(update.size && { size: update.size }),
        ...(update.shape && { shape: update.shape }),
        ...(update.positioning && { positioning: update.positioning }),
        ...(update.bubble && { bubble: update.bubble }),
        border: {
          ...prev.border,
          ...(update.border || {}),
          gradientColors: update.border?.gradientColors
            ? ensureArray(update.border.gradientColors)
            : ensureArray(prev.border.gradientColors),
        },
      };

      console.log('Debug - Updating config:', {
        currentGradientColors: ensureArray(prev.border.gradientColors),
        newGradientColors: newConfig.border.gradientColors,
        isArray: Array.isArray(newConfig.border.gradientColors),
      });

      debouncedHandleConfigurationChange(newConfig);
      return newConfig;
    });
  };

  const handleSizeChange = (e: SelectChangeEvent<string>) => {
    updateConfig({ size: e.target.value as WidgetSize });
  };

  const handleGradientColorChange = (index: number) => (color: string) => {
    const currentColors = widgetConfig.border.gradientColors || defaultGradientColors;

    // Ensure we create a proper array
    const newGradientColors = [
      index === 0 ? color : currentColors[0],
      index === 1 ? color : currentColors[1],
    ];

    handleBorderChange({
      gradientColors: newGradientColors,
    });
  };

  const handleBorderChange = (newBorderProperty: Partial<BorderType>) => {
    const updatedEnabled =
      newBorderProperty.enabled !== undefined
        ? newBorderProperty.enabled
        : widgetConfig.border.enabled;

    let updatedGradientColors: [string, string] | null = null;

    if (updatedEnabled) {
      if (newBorderProperty.gradientColors) {
        updatedGradientColors = ensureGradientColorsTuple(newBorderProperty.gradientColors);
      } else if (widgetConfig.border.gradientColors) {
        updatedGradientColors = ensureGradientColorsTuple(widgetConfig.border.gradientColors);
      } else {
        updatedGradientColors = defaultGradientColors;
      }
    }

    const updatedBorder: BorderType = {
      ...widgetConfig.border,
      ...newBorderProperty,
      gradientColors: updatedGradientColors,
    };

    updateConfig({
      border: updatedBorder,
    });
  };

  const handlePositionChange = (e: SelectChangeEvent<string>) => {
    updateConfig({
      positioning: { ...widgetConfig.positioning, position: e.target.value as WidgetPosition },
    });
  };

  const handleMarginChange = (
    position: 'bottomLeft' | 'bottomRight',
    field: 'marginBottom' | 'marginLeft' | 'marginRight',
    value: number,
  ) => {
    updateConfig({
      positioning: {
        ...widgetConfig.positioning,
        [position]: {
          ...widgetConfig.positioning[position],
          [field]: value,
        },
      },
    });
  };

  const handleBubbleChange = (
    property: 'enabled' | 'text' | 'backgroundColor' | 'textColor',
    value: string | boolean,
  ) => {
    if (property === 'enabled' && value === true) {
      // When enabling the bubble, set default values if they're empty
      updateConfig({
        bubble: {
          enabled: true,
          text: widgetConfig.bubble.text || defaultBubbleText,
          design: {
            backgroundColor:
              widgetConfig.bubble.design.backgroundColor || defaultBubbleBackgroundColor,
            textColor: widgetConfig.bubble.design.textColor || defaultBubbleTextColor,
          },
        },
      });
    } else {
      updateConfig({
        bubble: {
          ...widgetConfig.bubble,
          ...(property === 'backgroundColor' || property === 'textColor'
            ? { design: { ...widgetConfig.bubble.design, [property]: value } }
            : { [property]: value }),
        },
      });
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 3 }}>
      <Grid container spacing={3} sx={{ minHeight: '75vh' }}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Widget Size</InputLabel>
            <Select value={widgetConfig.size} onChange={handleSizeChange}>
              <MenuItem value="S">Small</MenuItem>
              <MenuItem value="M">Medium</MenuItem>
              <MenuItem value="L">Large</MenuItem>
            </Select>
          </FormControl>
          <Box mt={2}>
            <Typography variant="subtitle1">Border</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={widgetConfig.border.enabled}
                  onChange={(e) => handleBorderChange({ enabled: e.target.checked })}
                />
              }
              label="Border Enabled"
            />
            {widgetConfig.border.enabled && widgetConfig.border.gradientColors && (
              <>
                <ColorPickerV2
                  defaultColor={defaultGradientColors[0]}
                  color={widgetConfig.border.gradientColors[0]}
                  onChangeComplete={handleGradientColorChange(0)}
                  label="Gradient Start Color"
                />
                <ColorPickerV2
                  defaultColor={defaultGradientColors[1]}
                  color={widgetConfig.border.gradientColors[1]}
                  onChangeComplete={handleGradientColorChange(1)}
                  label="Gradient End Color"
                />
              </>
            )}
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle1">Position</Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel>Widget Position</InputLabel>
              <Select value={widgetConfig.positioning.position} onChange={handlePositionChange}>
                <MenuItem value="bottomLeft">Bottom Left</MenuItem>
                <MenuItem value="bottomRight">Bottom Right</MenuItem>
              </Select>
            </FormControl>
            {widgetConfig.positioning.position === 'bottomLeft' && (
              <>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Margin Bottom"
                  type="number"
                  value={widgetConfig.positioning.bottomLeft?.marginBottom ?? 0}
                  onChange={(e) =>
                    handleMarginChange('bottomLeft', 'marginBottom', Number(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0 } }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Margin Left"
                  type="number"
                  value={widgetConfig.positioning.bottomLeft?.marginLeft ?? 0}
                  onChange={(e) =>
                    handleMarginChange('bottomLeft', 'marginLeft', Number(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </>
            )}
            {widgetConfig.positioning.position === 'bottomRight' && (
              <>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Margin Bottom"
                  type="number"
                  value={widgetConfig.positioning.bottomRight?.marginBottom ?? 0}
                  onChange={(e) =>
                    handleMarginChange('bottomRight', 'marginBottom', Number(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0 } }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Margin Right"
                  type="number"
                  value={widgetConfig.positioning.bottomRight?.marginRight ?? 0}
                  onChange={(e) =>
                    handleMarginChange('bottomRight', 'marginRight', Number(e.target.value))
                  }
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </>
            )}
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle1">Note bubble</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={widgetConfig.bubble.enabled}
                  onChange={(e) => handleBubbleChange('enabled', e.target.checked)}
                />
              }
              label="Enable bubble with your note"
            />
            {widgetConfig.bubble.enabled && (
              <>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Text"
                  value={widgetConfig.bubble.text || defaultBubbleText}
                  onChange={(e) => handleBubbleChange('text', e.target.value)}
                />
                <ColorPicker
                  defaultColor={defaultBubbleBackgroundColor}
                  color={widgetConfig.bubble.design.backgroundColor || defaultBubbleBackgroundColor}
                  onChangeComplete={(color) =>
                    color && handleBubbleChange('backgroundColor', color)
                  }
                  label="Background Color"
                />
                <ColorPicker
                  defaultColor={defaultBubbleTextColor}
                  color={widgetConfig.bubble.design.textColor || defaultBubbleTextColor}
                  onChangeComplete={(color) => color && handleBubbleChange('textColor', color)}
                  label="Text Color"
                />
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <WidgetPreview
            clientId={clientId}
            controllerId={controllerData?.id || null}
            reloadTrigger={previewReload}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StyleMinifiedStep;
