import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ComputerIcon, MobileIcon } from '../../assets/icons';
import { appConfig } from '../../app-config';

interface WidgetPreviewProps {
  controllerId: string | null;
  reloadTrigger: number;
  clientId: string;
  fullscreenMode?: boolean;
  title?: string;
}

const WidgetPreview: React.FC<WidgetPreviewProps> = ({
  controllerId,
  reloadTrigger,
  clientId,
  fullscreenMode = false,
  title = 'Widget Preview',
}) => {
  const [viewMode, setViewMode] = useState<'desktop' | 'mobile'>(
    fullscreenMode ? 'mobile' : 'desktop',
  );
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: 'desktop' | 'mobile' | null,
  ) => {
    if (newMode !== null && !fullscreenMode) {
      setViewMode(newMode);
    }
  };

  const updateIframeContent = () => {
    if (controllerId && iframeRef.current) {
      const iframeContent = fullscreenMode
        ? `
          <!DOCTYPE html>
          <html>
            <head>
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <style>
                body { 
                  margin: 0; 
                  padding: 0;
                  background: transparent;
                }
              </style>
            </head>
            <body>
              <script id="closer2-widget" 
                src="${appConfig.widgetUrl}" 
                data-c2-controller-id="${controllerId}"
                data-c2-client-id="${clientId}"
                data-c2-fullscreen-mode="${fullscreenMode}" 
                async>
              </script>
            </body>
          </html>
        `
        : `
          <!DOCTYPE html>
          <html>
            <head>
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <link href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css" rel="stylesheet">
              <style>
                body { 
                  font-family: Arial, sans-serif; 
                  margin: 0; 
                  padding: 0;
                  background-color: #f8f9fa;
                }
                
                .skeleton {
                  background: linear-gradient(
                    90deg,
                    #f0f0f0 25%,
                    #e0e0e0 50%,
                    #f0f0f0 75%
                  );
                  background-size: 200% 100%;
                  animation: false;
                }
                
                .header {
                  background-color: white;
                  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                }

                .content {
                  max-width: 1200px;
                  margin: 0 auto;
                  padding: 20px;
                }

                .hero-section {
                  background-color: white;
                  border-radius: 8px;
                  margin-bottom: 24px;
                  padding: 32px;
                  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                }

                .card-grid {
                  display: grid;
                  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
                  gap: 24px;
                }

                .card {
                  background-color: white;
                  border-radius: 8px;
                  overflow: hidden;
                  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                }
              </style>
            </head>
            <body>
              <!-- Header -->
              <header class="header p-4">
                <div class="content flex items-center justify-between">
                  <div class="flex items-center gap-8">
                    <div class="skeleton w-32 h-8 rounded"></div>
                    <div class="hidden md:flex gap-6">
                      <div class="skeleton w-20 h-4 rounded"></div>
                      <div class="skeleton w-20 h-4 rounded"></div>
                      <div class="skeleton w-20 h-4 rounded"></div>
                    </div>
                  </div>
                  <div class="skeleton w-64 h-10 rounded-full"></div>
                </div>
              </header>

              <main class="content">
                <section class="hero-section">
                  <div class="skeleton w-3/4 h-12 rounded mb-6"></div>
                  <div class="skeleton w-full h-64 rounded mb-6"></div>
                  <div class="flex gap-4">
                    <div class="skeleton w-32 h-10 rounded"></div>
                    <div class="skeleton w-32 h-10 rounded"></div>
                  </div>
                </section>

                <div class="card-grid">
                  ${Array(6)
                    .fill(0)
                    .map(
                      () => `
                    <div class="card">
                      <div class="skeleton w-full h-48"></div>
                      <div class="p-4">
                        <div class="skeleton w-3/4 h-6 rounded mb-4"></div>
                        <div class="skeleton w-full h-4 rounded mb-2"></div>
                        <div class="skeleton w-2/3 h-4 rounded"></div>
                      </div>
                    </div>
                  `,
                    )
                    .join('')}
                </div>
              </main>

              <script id="closer2-widget" 
                src="${appConfig.widgetUrl}" 
                data-c2-controller-id="${controllerId}"
                data-c2-client-id="${clientId}"
                data-c2-fullscreen-mode="${fullscreenMode}" 
                async>
              </script>
            </body>
          </html>
        `;

      iframeRef.current.srcdoc = iframeContent;
    }
  };

  useEffect(() => {
    updateIframeContent();
  }, [controllerId, reloadTrigger, fullscreenMode]);

  if (fullscreenMode) {
    return (
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
            overflow: 'hidden',
            minHeight: 0,
          }}
        >
          {controllerId ? (
            <Box sx={{ width: '50%', height: '100%' }}>
              <iframe
                ref={iframeRef}
                title="Widget Preview"
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  background: 'transparent',
                }}
              />
            </Box>
          ) : (
            <Typography>Upload a video to see the preview.</Typography>
          )}
        </Box>
      </Box>
    );
  }

  // Original non-fullscreen behavior
  const dimensions =
    viewMode === 'mobile'
      ? { width: '375px', height: '667px' }
      : { width: '100%', height: '0', paddingBottom: '56.25%' };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">{title}</Typography>
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={handleViewChange}
          aria-label="view mode"
        >
          <ToggleButton value="desktop" aria-label="desktop view">
            <ComputerIcon />
          </ToggleButton>
          <ToggleButton value="mobile" aria-label="mobile view">
            <MobileIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: viewMode === 'mobile' ? '667px' : 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f5f5f5',
          border: '1px solid #ccc',
          borderRadius: '4px',
          overflow: 'hidden',
        }}
      >
        {controllerId ? (
          <Box
            sx={{
              position: 'relative',
              width: dimensions.width,
              height: dimensions.height,
              paddingBottom: viewMode === 'desktop' ? dimensions.paddingBottom : 0,
            }}
          >
            <iframe
              ref={iframeRef}
              title="Widget Preview"
              style={{
                position: viewMode === 'desktop' ? 'absolute' : 'relative',
                top: 0,
                left: 0,
                width: '100%',
                height: viewMode === 'desktop' ? '100%' : '667px',
                border: 'none',
              }}
              frameBorder="0"
            />
          </Box>
        ) : (
          <Typography>Upload a video to see the preview.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default WidgetPreview;
