import React, { useState, useEffect } from 'react';
import { Box, TextField, Chip, Typography, Button, Alert, Paper } from '@mui/material';
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { usePatchCustomerSettingsMutation } from '../../hooks/api/patchCustomerSettings/patchCustomerSettings.generated';
import { useCustomerSettings } from '../../contexts/CustomerSettingsContext';
import { CloseIcon, PlusIcon } from '../../assets/icons';

interface TrustedDomainsManagerProps {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
}

const TrustedDomainsManager: React.FC<TrustedDomainsManagerProps> = ({
  control,
  setValue,
  watch,
}) => {
  const [newDomain, setNewDomain] = useState('');
  const [error, setError] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);

  const { customer, refetchCustomer } = useCustomerSettings();
  const domainLimit = customer?.currentSubscription?.limits?.domainLimit || 0;

  const trustedDomains = watch('trustedDomains') || [];

  // Initialize domains from customer settings or keep existing form state
  useEffect(() => {
    if (!isInitialized && customer) {
      const customerDomains = customer?.customerSettings?.trustedDomains || [];
      setValue('trustedDomains', customerDomains);
      setIsInitialized(true);
    }
  }, [customer, setValue, isInitialized]);

  const [patchCustomerSettings, { loading }] = usePatchCustomerSettingsMutation();

  const validateDomain = (domain: string) => {
    const pattern = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
    return pattern.test(domain);
  };

  const updateDomains = async (domains: string[]) => {
    await patchCustomerSettings({
      variables: {
        input: {
          trustedDomains: domains,
        },
      },
    });

    // Refetch customer data instead of manual update
    await refetchCustomer();

    // Still update form state since that's local to the form
    setValue('trustedDomains', domains);
  };

  const handleAddDomain = async () => {
    try {
      setError('');

      if (!newDomain) {
        setError('Domain cannot be empty');
        return;
      }

      if (!validateDomain(newDomain)) {
        setError('Please enter a valid domain (e.g., example.com)');
        return;
      }

      if (trustedDomains.includes(newDomain)) {
        setError('This domain is already in the list');
        return;
      }

      if (trustedDomains.length >= domainLimit) {
        setError(`Cannot add more domains. Limit of ${domainLimit} domains reached.`);
        return;
      }

      const updatedDomains = [...trustedDomains, newDomain];
      await updateDomains(updatedDomains);
      setNewDomain('');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to add domain. Please try again.');
    }
  };

  const handleRemoveDomain = async (domainToRemove: string) => {
    try {
      setError('');
      const updatedDomains = trustedDomains.filter((domain: string) => domain !== domainToRemove);
      await updateDomains(updatedDomains);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to remove domain. Please try again.');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !loading && newDomain) {
      e.preventDefault();
      handleAddDomain();
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1" gutterBottom>
        Trusted Domains ({trustedDomains.length}/{domainLimit})
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Add domains where your widget will be hosted. Only listed domains will be able to load the
          widget.
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
        <TextField
          size="small"
          fullWidth
          placeholder="Enter domain (e.g., example.com)"
          value={newDomain}
          onChange={(e) => setNewDomain(e.target.value.toLowerCase())}
          error={!!error}
          disabled={loading}
          onKeyPress={handleKeyPress}
        />
        <Button
          variant="contained"
          onClick={handleAddDomain}
          disabled={loading || !newDomain}
          startIcon={<PlusIcon />}
        >
          Add
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Controller
        name="trustedDomains"
        control={control}
        render={({ field }) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {field.value?.map((domain: string) => (
              <Chip
                key={domain}
                label={domain}
                onDelete={() => handleRemoveDomain(domain)}
                deleteIcon={<CloseIcon />}
              />
            ))}
          </Box>
        )}
      />
    </Box>
  );
};

export default TrustedDomainsManager;
