import React, { useState } from 'react';
import { Box, Typography, Paper, Button, Tabs, Tab, Alert, Collapse, styled } from '@mui/material';
import { ChevronDown, ChevronUp, Copy, Play, Sparkles } from 'lucide-react';
import { useCustomerSettings } from '../../contexts/CustomerSettingsContext';
import { appConfig } from '../../app-config';

interface EmbedStepProps {
  controllerId: string | null;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CodeBlock = styled(Box)(({ theme }) => ({
  backgroundColor: '#1e293b',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  color: '#fff',
  fontFamily: 'monospace',
  position: 'relative',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`embed-tabpanel-${index}`}
      aria-labelledby={`embed-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

export const generateEmbedCode = (controllerId: string | null, clientId: string | undefined) => {
  return `<script id="closer2-widget"
    src="https://cdn.eu-west-1${appConfig.isPreProd ? '.dev' : ''}.closer2.io/scripts/widget.js"
    data-c2-controller-id="${controllerId}"
    data-c2-client-id="${clientId}"
    async></script>`;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: 'calc(90vh - 120px)',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
}));

const ScrollableContent = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  paddingBottom: '100px', // Space for support section
});

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#441078', // Purple color from your design
    height: '3px',
  },
  '& .MuiTab-root': {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#666',
    '&.Mui-selected': {
      color: '#441078',
      fontWeight: '500',
    },
  },
});

const CopyButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#441078',
  color: 'white',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#5433d6',
  },
}));

const SupportSection = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  '& .support-box': {
    border: '1px solid #441078',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    display: 'inline-block',
    position: 'relative',
  },
  '& .arrow': {
    position: 'absolute',
    bottom: -theme.spacing(8),
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100px',
    height: '60px',
    backgroundImage: 'url(/path-to-your-arrow.svg)', // You'll need to provide the arrow image
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
}));

const EmbedStep: React.FC<EmbedStepProps> = ({ controllerId }) => {
  const customerSettingsMethods = useCustomerSettings();
  const [tabValue, setTabValue] = useState(0);
  const [showFullScript, setShowFullScript] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const embedCode = generateEmbedCode(controllerId, customerSettingsMethods?.customer?.clientId);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(embedCode);
  };

  return (
    <StyledPaper elevation={3}>
      <ScrollableContent>
        <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
          <CopyButton variant="contained" startIcon={<Copy size={18} />} onClick={handleCopyClick}>
            COPY WIDGET CODE
          </CopyButton>
          <Button
            variant="text"
            onClick={() => setShowFullScript(!showFullScript)}
            endIcon={showFullScript ? <ChevronUp /> : <ChevronDown />}
            sx={{ color: '#666' }}
          >
            SHOW CODE
          </Button>
        </Box>
        <Collapse in={showFullScript}>
          <CodeBlock>
            <code>{embedCode}</code>
          </CodeBlock>
        </Collapse>

        <StyledTabs value={tabValue} onChange={handleTabChange}>
          <Tab label="GENERAL INSTRUCTIONS" />
          <Tab label="WORDPRESS" />
        </StyledTabs>

        <TabPanel value={tabValue} index={0}>
          <Typography variant="body1" paragraph>
            Follow these steps to add the widget to your website:
          </Typography>

          <Typography variant="subtitle1" gutterBottom fontWeight="bold">
            1. Add to Your Website
          </Typography>
          <Typography variant="body1" paragraph>
            Place the code in the <code>&lt;head&gt;</code> section of your HTML file or website
            builder&apos;s custom code section.
          </Typography>

          <Typography variant="subtitle1" gutterBottom fontWeight="bold">
            2. Verify Installation
          </Typography>
          <Typography variant="body1" paragraph>
            After adding the code, refresh your website to see the widget in action.
          </Typography>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <Sparkles size={24} />
            <Typography variant="body1" fontWeight="bold">
              Recommended: Use &quot;Headers and Footers&quot; Plugin
            </Typography>
          </Box>

          <Alert severity="info" sx={{ mb: 3 }}>
            The Headers and Footers plugin makes it easy to add our widget code without editing your
            theme files.
          </Alert>

          <Typography variant="subtitle1" gutterBottom fontWeight="bold">
            Installation Steps:
          </Typography>

          <Box component="ol" sx={{ pl: 2 }}>
            <li>
              <Typography paragraph>
                Install and activate the &quot;Headers and Footers&quot; plugin from the WordPress
                plugin directory. See plugin website for more information{' '}
                <a
                  href="https://wordpress.org/plugins/wp-headers-and-footers/#can%20we%20use%20this%20headers%20and%20footers%20plugin%20to%20verify%20our%20website%20on%20different%20platforms%3F"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </Typography>
            </li>
            <li>
              <Typography paragraph>
                Go to Settings → Headers and Footers in your WordPress dashboard
              </Typography>
            </li>
            <li>
              <Typography paragraph>
                Copy our widget code and paste it into the &quot;Header&quot; section
              </Typography>
            </li>
            <li>
              <Typography paragraph>Click &quot;Save&quot; to apply the changes</Typography>
            </li>
          </Box>

          <Alert severity="success" sx={{ mt: 3 }}>
            <Typography variant="body2">
              The plugin will automatically add our widget code to all pages of your WordPress site
              or you can configure it only on specific pages.
            </Typography>
          </Alert>
        </TabPanel>
      </ScrollableContent>

      <SupportSection>
        <div className="support-box">
          <Typography variant="body2" sx={{ mb: 1 }}>
            Need help? Contact our support:
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#441078',
              fontWeight: '500',
              textTransform: 'uppercase',
            }}
          >
            <a
              href="mailto:support@closer2.io"
              style={{ color: '#441078', textDecoration: 'none' }}
            >
              support@closer2.io
            </a>
          </Typography>
          <div className="arrow" />
        </div>
      </SupportSection>
    </StyledPaper>
  );
};

export default EmbedStep;
