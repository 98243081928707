import React, { useEffect, useState, useCallback } from 'react';
import { debounce, isEqual } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { Box, Typography, TextField, Grid, Switch, FormControlLabel, Paper } from '@mui/material';
import WidgetPreview from './WidgetPreview';
import DateInput from '../Common/DateInput';
import { useCustomerSettings } from '../../contexts/CustomerSettingsContext';
import TrustedDomainsManager from './TrustedDomainsManager';

interface ConfigureActionsStepProps {
  onConfigurationComplete: (config: any) => void;
  initialData?: any;
  controllerId: string | null;
  previewReload: number;
}

const ConfigureActionsStep: React.FC<ConfigureActionsStepProps> = ({
  onConfigurationComplete,
  initialData,
  controllerId,
  previewReload,
}) => {
  const customerSettingsMethods = useCustomerSettings();

  const defaultValues = {
    controllerName: initialData?.name || '',
    isAllTime: initialData?.validFrom == null && initialData?.validUntil == null,
    validFrom: initialData?.validFrom || null,
    validUntil: initialData?.validUntil || null,
    actionButtonLabel: initialData?.stages?.[0]?.actions?.[0]?.label || '',
    instructionsText:
      initialData?.stages?.[0]?.actions?.[0]?.emailCollection?.instructionsText || '',
    disclaimerText: initialData?.stages?.[0]?.actions?.[0]?.emailCollection?.disclaimerText || '',
    trustedDomains: customerSettingsMethods?.customer?.customerSettings?.trustedDomains || [],
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const [isSaving, setIsSaving] = useState(false);
  const [lastSavedValues, setLastSavedValues] = useState(defaultValues);

  const formValues = watch();

  const saveChanges = useCallback(
    (data: any) => {
      setIsSaving(true);
      const config = {
        name: data.controllerName,
        isAllTime: data.isAllTime,
        scheduling: {
          validFrom: data.isAllTime ? null : data.validFrom,
          validUntil: data.isAllTime ? null : data.validUntil,
        },
        action: {
          buttonLabel: data.actionButtonLabel,
          instructionsText: data.instructionsText,
          disclaimerText: data.disclaimerText,
        },
      };
      onConfigurationComplete(config);
      setLastSavedValues(data);
      setIsSaving(false);
    },
    [onConfigurationComplete],
  );

  const debouncedSaveChanges = useCallback(debounce(saveChanges, 1000), [saveChanges]);

  useEffect(() => {
    if (isDirty && !isEqual(formValues, lastSavedValues)) {
      debouncedSaveChanges(formValues);
    }
  }, [formValues, isDirty, lastSavedValues, debouncedSaveChanges]);

  const handleIsAllTimeToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('isAllTime', event.target.checked);
    if (event.target.checked) {
      setValue('validFrom', null);
      setValue('validUntil', null);
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 3 }}>
      <Grid container spacing={3} sx={{ minHeight: '75vh' }}>
        <Grid item xs={12} md={6}>
          <Box component="form" onSubmit={handleSubmit(saveChanges)}>
            <Typography variant="h6" gutterBottom>
              Personalize your controller
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="controllerName"
                  control={control}
                  render={({ field }) => <TextField {...field} fullWidth label="Controller Name" />}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="isAllTime"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch {...field} checked={field.value} onChange={handleIsAllTimeToggle} />
                      }
                      label="Active at all times"
                    />
                  )}
                />
              </Grid>

              {!watch('isAllTime') && (
                <>
                  <Grid item xs={6}>
                    <DateInput label="Valid from" name="validFrom" control={control} />
                  </Grid>
                  <Grid item xs={6}>
                    <DateInput label="Valid until" name="validUntil" control={control} />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Controller
                  name="actionButtonLabel"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} fullWidth label="Label for action button" />
                  )}
                />
              </Grid>

              {/* <Grid item xs={12}>
                <Controller
                  name="instructionsText"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} fullWidth label="Add instructions text" />
                  )}
                />
              </Grid> */}

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="disclaimerText"
                  render={({ field }) => (
                    <TextField {...field} fullWidth label="Add disclaimer text" />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                {isDirty && (
                  <Typography color="textSecondary" variant="body2" style={{ marginTop: '8px' }}>
                    Unsaved changes will be automatically saved after 1 second of inactivity
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <TrustedDomainsManager control={control} setValue={setValue} watch={watch} />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <WidgetPreview
            clientId={customerSettingsMethods?.customer?.clientId!}
            controllerId={controllerId}
            fullscreenMode
            reloadTrigger={previewReload}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ConfigureActionsStep;
