import React, { useState } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Box, Stepper, Step, StepLabel, useMediaQuery, useTheme, Button } from '@mui/material';
import ErrorSnackbar from '../Utils/ErrorSnackbar';
import VideoUploadForm from './VideoUploadForm';
import ConfigureActionsStep from './ConfigureActionsStep';
import StyleMinifiedStep, { StyleMinifedWidgetConfig } from './StyleMinifiedStep';
import { useCreateControllerMutation } from '../../hooks/api/createController/createController.generated';
import { ControllerType, PatchControllerInputType, PositioningType } from '../../interfaces';
import { usePatchControllerMutation } from '../../hooks/api/patchController/patchController.generated';
import EmbedStep from './EmbedStep';
import { useCustomerSettings } from '../../contexts/CustomerSettingsContext';

// add positioning and bubble
// go over responsiveness of the wizard

const steps = ['Upload your video', 'Closed controller', 'Open controller', 'Embed'];

const VideoUploadWidgetDashboard: React.FC = () => {
  const customerSettingsMethods = useCustomerSettings();

  const [activeStep, setActiveStep] = useState(0);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [controllerData, setControllerData] = useState<ControllerType | null>(null);
  const [uploadedAsset, setUploadedAsset] = useState<any>(null);
  const [previewReload, setPreviewReload] = useState<number>(0);
  const [patchControllerMutation] = usePatchControllerMutation();

  const [createControllerMutation] = useCreateControllerMutation();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const handleSnackbarClose = () => {
    setErrorSnackbarOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const cleanObject = (obj: any): any => {
    const cleaned = { ...obj };
    Object.keys(cleaned).forEach((key) => {
      if (key === '__typename') {
        delete cleaned[key];
      } else if (typeof cleaned[key] === 'object' && cleaned[key] !== null) {
        cleaned[key] = cleanObject(cleaned[key]);
      }
    });
    return cleaned;
  };

  const handleUploadComplete = async (assetData: any) => {
    setUploadedAsset(assetData);
    try {
      const result = await createControllerMutation({
        variables: {
          input: {
            name: assetData.name,
            isActive: true,
            initialStageId: '0',
            stages: [
              {
                id: '0',
                name: 'Initial Stage',
                content: {
                  id: assetData.id || assetData.videoAsset.fileId,
                  type: 'video',
                  videoAssetId: assetData.id || assetData.videoAsset.fileId,
                  videoAssetName: assetData.name,
                  videoAssetUrl: assetData.videoAsset.fileId,
                },
                actions: [
                  {
                    type: 'emailCollection',
                    isFinal: true,
                    closeWidget: true,
                    order: 0,
                    label: 'Leave us your email',
                    emailCollection: {
                      instructionsText: 'Please leave us your email to get a discount',
                      disclaimerText: 'Terms & Conditions',
                      disclaimerUrl: 'https://www.closer2.io/terms-and-conditions',
                    },
                  },
                ],
              },
            ],
            layout: {
              type: 'widget',
              widget: {
                design: {
                  minified: {
                    widgetSize: 'M',
                    shape: 'circle',
                    caption: {
                      text: assetData.name,
                    },
                    border: {
                      enabled: true,
                      gradientColors: ['#00df81', '#653ffe'],
                    },
                    positioning: {
                      position: 'bottomLeft',
                      bottomLeft: {
                        marginBottom: 20,
                        marginLeft: 20,
                      },
                      bottomRight: {
                        marginBottom: 20,
                        marginRight: 20,
                      },
                    },
                    addOns: {
                      bubble: {
                        enabled: true,
                        text: 'get a discount!', // duplicated with style minified component
                        design: {
                          backgroundColor: '#653ffe',
                          textColor: '#ffffff',
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });

      if (result.data?.createController) {
        setControllerData(cleanObject(result.data.createController));
        setActiveStep((prevStep) => prevStep + 1);
      } else {
        throw new Error('Failed to create controller');
      }
    } catch (error) {
      console.error('Error creating controller:', error);
      setErrorMessage((error as Error)?.message || 'Unknown error');
      setErrorSnackbarOpen(true);
    }
  };

  const handleConfigurationComplete = async (config: any) => {
    if (!controllerData) return;

    const updates: PatchControllerInputType = {
      id: controllerData.id,
      changes: [
        { path: 'name', value: config.name },
        { path: 'isAllTime', value: config.isAllTime },
        { path: 'validFrom', value: config.isAllTime ? null : config.scheduling.validFrom },
        { path: 'validUntil', value: config.isAllTime ? null : config.scheduling.validUntil },
        { path: 'stages[0].actions[0].type', value: 'emailCollection' },
        { path: 'stages[0].actions[0].isFinal', value: true },
        { path: 'stages[0].actions[0].closeWidget', value: true },
        { path: 'stages[0].actions[0].order', value: 0 },
        { path: 'stages[0].actions[0].label', value: config.action.buttonLabel },
        {
          path: 'stages[0].actions[0].emailCollection.instructionsText',
          value: config.action.instructionsText,
        },
        {
          path: 'stages[0].actions[0].emailCollection.disclaimerText',
          value: config.action.disclaimerText,
        },
        {
          path: 'stages[0].actions[0].emailCollection.disclaimerUrl', // TODO not ideal. We should default it somehow in the backend?
          value: 'https://www.closer2.io/terms-and-conditions',
        },
      ].filter((change) => change.value !== undefined),
    };

    try {
      const result = await patchControllerMutation({
        variables: {
          input: updates,
        },
      });

      if (result.data?.patchController) {
        setControllerData(cleanObject(result.data.patchController));
        setPreviewReload((prevReload) => prevReload + 1);
      } else {
        throw new Error('Failed to update controller');
      }
    } catch (error) {
      console.error('Error updating controller:', error);
      setErrorMessage((error as Error)?.message || 'Unknown error');
      setErrorSnackbarOpen(true);
    }
  };

  const handleStyleChange = async (config: Partial<StyleMinifedWidgetConfig>) => {
    if (!controllerData) return;

    const updates: PatchControllerInputType = {
      id: controllerData.id,
      changes: [],
    };

    if (config.size) {
      updates.changes.push({
        path: 'layout.widget.design.minified.widgetSize',
        value: config.size,
      });
    }

    if (config.shape) {
      updates.changes.push({
        path: 'layout.widget.design.minified.shape',
        value: config.shape,
      });
    }

    if (config.border) {
      updates.changes.push({
        path: 'layout.widget.design.minified.border',
        value: config.border,
      });
    }

    if (config.positioning) {
      updates.changes.push({
        path: 'layout.widget.design.minified.positioning',
        value: config.positioning,
      });
    }

    if (config.bubble) {
      updates.changes.push({
        path: 'layout.widget.design.minified.addOns.bubble',
        value: config.bubble,
      });
    }

    try {
      const result = await patchControllerMutation({
        variables: {
          input: updates,
        },
      });

      if (result.data?.patchController) {
        setControllerData(cleanObject(result.data.patchController));
        setPreviewReload((prevReload) => prevReload + 1);
      } else {
        throw new Error('Failed to patch controller');
      }
    } catch (error) {
      console.error('Error patching controller:', error);
      setErrorMessage((error as Error)?.message || 'Unknown error');
      setErrorSnackbarOpen(true);
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <VideoUploadForm onUploadComplete={handleUploadComplete} />;
      case 1:
        return (
          <StyleMinifiedStep
            clientId={customerSettingsMethods?.customer?.clientId!}
            controllerData={controllerData}
            handleConfigurationChange={handleStyleChange}
            previewReload={previewReload}
          />
        );
      case 2:
        return (
          <ConfigureActionsStep
            controllerId={controllerData?.id || null}
            initialData={controllerData}
            onConfigurationComplete={handleConfigurationComplete}
            previewReload={previewReload}
          />
        );
      // case 3:
      //   return (
      //     <FullscreenPreview
      //       clientId={customerSettingsMethods?.customer?.clientId!}
      //       controllerId={controllerData?.id!}
      //     />
      //   );
      case 3:
        return <EmbedStep controllerId={controllerData?.id!} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} sx={{ width: '90%' }}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box sx={{ mt: 2, mb: 1 }}>{renderStepContent()}</Box>
      {activeStep > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          {activeStep !== 1 && (
            <Button variant="outlined" color="primary" onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
          )}
          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep < steps.length && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (activeStep === steps.length - 1) {
                  window.location.href = '/controllers';
                } else {
                  handleNext();
                }
              }}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          )}
        </Box>
      )}
      <ErrorSnackbar
        message={errorMessage}
        onClose={handleSnackbarClose}
        open={errorSnackbarOpen}
      />
    </Box>
  );
};

export default VideoUploadWidgetDashboard;
